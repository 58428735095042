import './Dashboard.css'
import {useEffect, useState} from "react";
import axios from "axios";
import {Table} from "react-bootstrap";
import {Link} from "react-router-dom";

const Dashboard = ({role}) => {
    const [data, setData] = useState({
        users: [], bids: [], accounts: [], admins: []
    })

    useEffect(() => {
        axios('/api/users?limit=5&page=1&keyword=')
            .then((res) => {
                setData((prev) => ({...prev, users: [...res.data.data]}))
            })
            .catch((err) => {

            })
        axios('/api/accounts?limit=5&page=1&keyword=')
            .then((res) => {
                setData((prev) => ({...prev, accounts: [...res.data.data]}))
            })
            .catch((err) => {

            })
        axios('/api/logs/last-bid?limit=5&page=1&keyword=')
            .then((res) => {
                console.log()
                setData((prev) => ({...prev, bids: [...res.data.data]}))
            })
            .catch((err) => {

            })
        axios('/api/admins?limit=5&page=1&keyword=')
            .then((res) => {
                setData((prev) => ({...prev, admins: [...res.data.data]}))
            })
            .catch((err) => {

            })
    }, []);

    return (
        <div className={"dashboard-tickets"}>
            <div className={"row"}>
                <div className={"col-md-6"}>
                    <div className={"tickets-container color-red"}>
                        <div className={"ticket-header header-red"}><p>Recent Bids History</p></div>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Lot</th>
                                    <th>Username</th>
                                    <th>Auction</th>
                                    <th>Bid</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.bids.map((b) => {
                                        console.log(data.bids)
                                        return(
                                            <tr key={b.id * 100}>
                                                <td>{b.lotNumber}</td>
                                                <td>{b.username}</td>
                                                <td>{b.auction}</td>
                                                <td>{b.bid}</td>
                                                <td>{b.status}</td>
                                            </tr>
                                        )
                                    })
                                }
                                <tr>
                                    <td colSpan={5}><Link className={"view-all-logs"} to={"/users-logs"}>View All</Link></td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
                <div className={"col-md-6"}>
                    <div className={"tickets-container color-blue"}>
                        <div className={"ticket-header header-blue"}><p>Accounts</p></div>
                        <Table responsive>
                            <thead>
                            <tr>
                                <th>Auction</th>
                                <th>Buyer</th>
                                <th>Admin</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                data.accounts.map((a) => {
                                    return(
                                        <tr key={a.id * 1000}>
                                            <td>{a.auction}</td>
                                            <td>{a.buyer}</td>
                                            <td>{a.admin}</td>
                                        </tr>
                                    )
                                })
                            }
                            <tr>
                                <td colSpan={3}><Link className={"view-all-accounts"} to={"/accounts"}>View All</Link></td>
                            </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-6"}>
                    <div className={"tickets-container color-yellow"}>
                        <div className={"ticket-header header-yellow"}><p>Recent Added Users</p></div>
                        <Table responsive>
                            <thead>
                            <tr>
                                <th>Full Name</th>
                                <th>Username</th>
                                <th>Phone</th>
                                <th>Email</th>
                                <th>Admin</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                data.users.map((u) => {
                                    return(
                                        <tr key={u.id * 10000}>
                                            <td>{`${u.name} ${u.surname}`}</td>
                                            <td>{u.username}</td>
                                            <td>{u.phone}</td>
                                            <td>{u.email}</td>
                                            <td>{u.adminName}</td>
                                        </tr>
                                    )
                                })
                            }
                            <tr>
                                <td colSpan={5}><Link className={"view-all-users"} to={"/users"}>View All</Link></td>
                            </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
                {role === "sadmin" && <div className={"col-md-6"}>
                    <div className={"tickets-container color-gray"}>
                        <div className={"ticket-header header-gray"}><p>Recent Added Admins</p></div>
                        <Table responsive>
                            <thead>
                            <tr>
                                <th>Full Name</th>
                                <th>Username</th>
                                <th>Phone</th>
                                <th>Email</th>
                                <th>Country</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                data.admins.map((a) => {
                                    return(
                                        <tr key={a.id * 100000}>
                                            <td>{`${a.name} ${a.surname}`}</td>
                                            <td>{a.username}</td>
                                            <td>{a.phone}</td>
                                            <td>{a.email}</td>
                                            <td>{a.country}</td>
                                        </tr>
                                    )
                                })
                            }
                            <tr>
                                <td colSpan={5}><Link className={"view-all-admins"} to={"/admins"}>View All</Link></td>
                            </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default Dashboard